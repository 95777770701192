import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadFile from "../components/UploadFile";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddMobileCredentials from "../components/AddMobileCredentials";
import Loader from "../components/Loader";
import moment from "moment";
import * as XLSX from "xlsx";
import AddHeadOfficeTransaction from "../components/AddHeadOfficeTransaction";

function DailyTransactionHeadOffice() {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isUpdatingCreds, setIsUpdatingCreds] = useState(false);
  const [walletBalence, setWalletBalence] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedFile(null);
  };
  const handleShow = () => setShow(true);

  const [showAddCred, setShowAddCred] = useState(false);

  const handleCloseAddCred = () => setShowAddCred(false);
  const handleShowAddCred = (data) => setShowAddCred(data);

  const navigate = useNavigate();
  const componentRef = useRef();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function handleFileUpload(event) {
    // event && event?.preventDefault();
    if (!selectedFile) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    }
    setIsUploading(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const formData = new FormData();
      // rawFile?.forEach((file) => {
      formData.append("document", selectedFile);
      // });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/bulkuploadpaymentreceipt`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: formData,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        handleClose();
        setTimeout(() => {
          !response?.data?.errorItems?.length && handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUploading(false);
      // setPage("0");
      getRows();
    }
  }

  async function handleDelete(row) {
    setIsDeleting(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/transactiondelete`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          id: row?.id,
        },
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setShowDeleteModal(false);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsDeleting(false);
      // setPage("0");
      getRows();
    }
  }

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/headofficetransaction`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
        },
      });

      setRows(response?.data?.data);
      setTotalCount(response?.data?.count);
      setWalletBalence(response?.data?.wallet_bal || 0);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function printOrExportData(isPrint, rows) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      let tableData;
      if (isPrint && rows?.length) {
        tableData = rows;
      } else {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/headofficetransaction`,
          method: "POST",
          headers: {
            jwt_token: token,
          },
          data: {
            paginate: false,
          },
        });
        tableData = response?.data?.data;
      }

      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
        <html>
          <head>
            <title>Print</title>
            <style>
            body {
              font-family: Arial, sans-serif;
            }
            .table-wrapper {
              width: 100%;
              border-collapse: collapse;
            }
            .table-wrapper th, .table-wrapper td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
            }
            .table-wrapper th {
              background-color: #f2f2f2;
            }
            .button {
              padding: 10px 20px;
              background-color: #AA731F;
              color: #fff;
              border: none;
              cursor: pointer;
              margin-top: 10px;
              border-radius: 5px;
            }
            .button:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }
            #selectAll {
              margin-right: 5px;
            }
            .heading {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              // margin-left: 20px;
              margin-right: 20px;
            }
          </style>
          </head>
          <body>
          <div class="heading">
            <h1>Daily Transaction Head Office</h1>
            <button id="printButton" class="button">Print</button>
          </div>
            <table class="table-wrapper">
              <thead>
                <tr>
                  <th><input checked type="checkbox" id="selectAll"></th>
                  <th>Sr. No.</th>
                  <th>Date</th>
                  <th>From</th>
                  <th>From Bank Code</th>
                  <th>To</th>
                  <th>To Bank Code</th>
                  <th>Cash Amount Paid</th>
                  <th>Cash Amount Received</th>
                  <th>Transaction Type</th>
                  <th>Cheque Number</th>
                  <th>Purpose Of Payment</th>
                  <th>Emp. Code</th>
                  <th>Any Rectification</th>
                </tr>
              </thead>
              <tbody>
                               ${tableData
                                 .map(
                                   (row, index) => `
                      <tr>
                        <td><input checked type="checkbox" class="rowCheckbox"></td>
                        <td class="sr-no">${index + 1}</td>
                        <td>${
                          row.date ? moment(row.date).format("DD MMM YYYY") : ""
                        }</td>
                        <td>${row.from_code || ""} ${
                                     row.fromdata?.name ? "-" : ""
                                   } ${row.fromdata?.name || ""}</td>
                        <td>${row.from_code || ""}</td>
                        <td>${row.to_code || ""} ${
                                     row.todata?.name ? "-" : ""
                                   } ${row.todata?.name || ""}</td>
                        <td>${row.to_code || ""}</td>
                        <td>${row.debit_amount || ""}</td>
                        <td>${row.credit_amount || ""}</td>
                        <td>${
                          row.trans_type === 1
                            ? "Cash"
                            : row.trans_type === 2
                            ? "Bank"
                            : row.trans_type === 3
                            ? "Cheque"
                            : ""
                        }</td>
                        <td>${row.cheque || ""}</td>
                        <td>${row.ledger_head || ""}</td>
                        <td>${row.contact_person || ""}</td>
                        <td>${row?.rectificationdata?.name || ""}</td>
                      </tr>
                    `
                                 )
                                 .join("")}
              </tbody>
            </table>
             <script>
            const printButton = document.getElementById('printButton');
            const checkboxes = document.querySelectorAll('.rowCheckbox');
            const selectAllCheckbox = document.getElementById('selectAll');

            function updatePrintButtonState() {
              const anyChecked = Array.from(checkboxes).some(
                (checkbox) => checkbox.checked
              );
              printButton.disabled = !anyChecked;
            }
              function updateSerialNumbers() {
                const visibleRows = Array.from(document.querySelectorAll('tbody tr')).filter(row => row.style.display !== 'none');
                visibleRows.forEach((row, index) => {
                  row.querySelector('.sr-no').textContent = index + 1;
                });
                }                     
            // Event listener for individual row checkboxes
            checkboxes.forEach((checkbox) => {
              checkbox.addEventListener('change', () => {
                updatePrintButtonState();
                // If any checkbox is unchecked, uncheck the 'Select All' checkbox
                if (!checkbox.checked) {
                  selectAllCheckbox.checked = false;
                }
              });
            });

            // Event listener for 'Select All' checkbox
            selectAllCheckbox.addEventListener('change', () => {
              const isChecked = selectAllCheckbox.checked;
              checkboxes.forEach((checkbox) => {
                checkbox.checked = isChecked;
              });
              updatePrintButtonState();
            });

            // Event listener for 'Print' button
            printButton.addEventListener('click', () => {
              // Hide unchecked rows
              checkboxes.forEach((checkbox) => {
                if (!checkbox.checked) {
                  checkbox.parentElement.parentElement.style.display = 'none';
                }
              });
              updateSerialNumbers();
              // Remove checkboxes column and the 'Print' button
              document.querySelectorAll('input[type="checkbox"]').forEach((el) => 
                 el.parentElement.remove()
                );
              printButton.remove();
              // Trigger print dialog
              window.print();
              // Close the window after printing or cancelling the print dialog
              window.close();
            });
          </script>
          </body>
        </html>
      `;

        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": parseInt(page) * limit + index + 1,
            Date: item.date ? moment(item.date).format("DD MMM YYYY") : "",
            From: `${item.from_code || ""} ${item.fromdata?.name ? "-" : ""} ${
              item.fromdata?.name || ""
            }`,
            "From Bank Code": item.from_code || "",
            To: `${item.to_code || ""} ${item.todata?.name ? "-" : ""} ${
              item.todata?.name || ""
            }`,
            "To Bank Code": item.to_code || "",
            "Cash Amount Paid": item.debit_amount || "",
            "Cash Amount Received": item.credit_amount || "",
            "Transaction Type": `${
              item?.trans_type == 1
                ? "Cash"
                : item?.trans_type == 2
                ? "Bank"
                : item?.trans_type == 3
                ? "Cheque"
                : ""
            }`,
            "Cheque No": item.cheque || "",
            "Purpose Of Payment": item.ledger_head || "",
            "Emp. Code": item.contact_person || "",
            "Any Rectification": item?.rectificationdata?.name || "",
          };

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `daily_transaction_head_office.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function handleAddEditMobileCreds(
    event,
    date,
    paymentType,
    fromCode,
    toCode,
    amount,
    handleCloseCallback,
    recBy,
    transType,
    ledgerHead,
    site,
    id,
    fromBank,
    toBank,
    cheque,
    rectification
  ) {
    event && event?.preventDefault();

    setIsUpdatingCreds(true);
    try {
      const token = localStorage.getItem("him_user_token");

      let data = {
        trans_date: moment(date).format("YYYY-MM-DD"),
        payment_type: paymentType,
        from_code: fromCode,
        from_bank_code: fromBank,
        to_code: toCode,
        to_bank_code: toBank,
        // amount: amount,
        id: showAddCred?.id,
        site: site,
        ledger_head: ledgerHead,
        trans_type: transType,
        cheque: cheque,
        rectification: rectification,
      };

      if (paymentType == 1) {
        data.debit_amount = amount;
        data.credit_amount = 0;
      } else {
        data.debit_amount = 0;
        data.credit_amount = amount;
      }

      if (!id) {
        data = {
          trans_date: moment(date).format("YYYY-MM-DD"),
          payment_type: paymentType,
          from_code: fromCode,
          from_bank_code: fromBank,
          to_code: toCode,
          to_bank_code: toBank,
          amount: amount,
          site: site,
          ledger_head: ledgerHead,
          trans_type: transType,
          cheque: cheque,
          rectification: rectification,
        };
      }

      const response = await axios({
        url: id
          ? `${process.env.REACT_APP_BASE_URL}/himallcodesheet/transactionupdate`
          : `${process.env.REACT_APP_BASE_URL}/himallcodesheet/updatedailytransaction`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddCred();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUpdatingCreds(false);
      getRows();
      // setPage("0");
    }
  }
  useEffect(() => {
    getRows();
  }, [page, limit]);
  // }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Daily transaction head office</h1>
          <div className="ms-auto d-flex gap-1 flex-wrap justify-content-end">
            <button
              className="button button-brown px-4 me-4"
              type="button"
              disabled={isDownloading}
              onClick={() => {
                setIsDownloading(true);
                const link = document.createElement("a");
                link.href = "/headofficepaymentreceipt1.xlsx";
                link.download = "daily_transaction_head_office.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsDownloading(false);
              }}
            >
              {isDownloading ? <Loader /> : "Download Sample"}
            </button>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handleShow}
            >
              Upload
            </button>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={() => printOrExportData(false)}
              disabled={!rows.length || isExporting}
            >
              {isExporting ? <Loader /> : "Export"}
            </button>
            {/* <button
              type="button"
              className="button button-brown mr-30"
              onClick={() => printOrExportData(true)}
              disabled={!rows.length || isPrinting}
            >
              {isPrinting ? <Loader /> : "Print"}
            </button> */}
            <select
              value={""}
              onChange={(e) => {
                if (e.target.value == "Print Selection") {
                  printOrExportData(true, rows);
                } else if (e.target.value == "Print All") {
                  printOrExportData(true);
                }
              }}
              className="button button-brown mr-30"
            >
              <option className="d-none" value="">
                {isPrinting ? <Loader /> : "Print"}
              </option>
              <option value="Print Selection">Print Selection</option>
              <option value="Print All">Print All</option>
            </select>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={() => handleShowAddCred(true)}
            >
              Add
            </button>
          </div>
        </div>

        {/* new add */}
        {/* <div className="mb-20 d-flex gray">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose debitor
            </label>
            <select className="input-box" id="cars" name="cars">
              <option value="">MCG</option>
              <option value="">MCG</option>
              <option value="">MCG</option>
              <option value="">MCG</option>
              <option value="">MCG</option>
            </select>
          </div>
          <div className="w-45 mr-30">
            <label htmlFor="" className="mb-10 d-block">
              Choose title
            </label>
            <select className="input-box" id="cars" name="cars">
              <option value="title">Choose title</option>
              <option value="title">Choose title #1</option>
              <option value="title">Choose title #2</option>
              <option value="title">Choose title #3</option>
              <option value="title">Choose title #4</option>
            </select>
          </div>
        </div>*/}
        <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          {/* <div>
            <h3 className="fs-20">Wallet Balance: {walletBalence}</h3>
          </div> */}
          {/* <div>
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handleShowAddCred}
            >
              Export
            </button>
          </div> */}
        </div>
        <div ref={componentRef} className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd"> Sr. No. </th>
                <th> Date </th>
                <th> From </th>
                <th> From Bank Code </th>
                <th> To </th>
                <th> To Bank Code </th>
                <th> Cash Amount Paid </th>
                <th> Cash Amount Received </th>
                <th> Transaction Type </th>
                <th> Cheque Number</th>
                {/* <th> Ledger Head </th> */}
                <th> Purpose Of Payment </th>
                <th> Emp. Code </th>
                <th> Any Rectification</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{parseInt(page) * limit + index + 1}</td>
                    {/* <td>{index + 1}</td> */}
                    <td>{moment(row?.date).format("DD MMM YYYY")}</td>
                    <td>
                      {row?.from_code} {row?.fromdata?.name ? "-" : ""}{" "}
                      {row?.fromdata?.name}
                    </td>
                    <td>
                      {row?.from_bank_code} {row?.frombank?.name ? "-" : ""}{" "}
                      {row?.frombank?.name}
                    </td>
                    <td>
                      {row?.to_code} {row?.todata?.name ? "-" : ""}{" "}
                      {row?.todata?.name}
                    </td>
                    <td>
                      {row?.to_bank_code} {row?.tobank?.name ? "-" : ""}{" "}
                      {row?.tobank?.name}
                    </td>
                    <td>{row?.debit_amount}</td>
                    <td>{row?.credit_amount}</td>
                    <td>
                      {row?.trans_type == 1
                        ? "Cash"
                        : row?.trans_type == 2
                        ? "Bank"
                        : row?.trans_type == 3
                        ? "Cheque"
                        : ""}
                    </td>
                    <td>{row?.cheque}</td>
                    <td>{row?.ledger_head}</td>
                    <td>{row?.contact_person}</td>
                    <td>{row?.rectificationdata?.name}</td>
                    <td>
                      <button
                        className="button  px-2"
                        type="button"
                        onClick={() => {
                          handleShowAddCred(row);
                        }}
                      >
                        <svg className="icon fs-22">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                      <button
                        className="button  px-2"
                        type="button"
                        onClick={() => {
                          setShowDeleteModal(row);
                        }}
                      >
                        <svg className="icon fs-22 text-danger">
                          <use href="#icon_delete"></use>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>

      <UploadFile
        modalTitle={"Upload Daily Transaction Head Office"}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        show={show}
        setShow={setShow}
        onUpload={handleFileUpload}
        isUploading={isUploading}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddHeadOfficeTransaction
        showAddCred={showAddCred}
        handleCloseAddCred={handleCloseAddCred}
        handleAddEditMobileCreds={handleAddEditMobileCreds}
        isUpdatingCreds={isUpdatingCreds}
        label={"Head Office Transaction"}
        payment_type={showAddCred?.payment_type}
      />
      <Modal
        show={showDeleteModal}
        centered
        onHide={() => {
          setShowDeleteModal(false);
        }}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <h3>Are you sure you want to delete this record?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button "
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            No
          </button>
          <button
            className="button button-brown"
            onClick={() => {
              handleDelete(showDeleteModal);
            }}
            disabled={isDeleting}
          >
            {isDeleting ? <Loader /> : "Yes"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DailyTransactionHeadOffice;
