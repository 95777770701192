import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../components/Loader";
import * as XLSX from "xlsx";

function ProfileVendor() {
  const navigate = useNavigate();
  const componentRef = useRef();

  //   const [show, setShow] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  //   aside
  const [show, setShow] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSite, setSelectedSite] = useState();
  const [siteList, setSiteList] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [isBillAdding, setIsBillAdding] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const handleClose = () => {
    setShow(false);
    setBankCode("");
    setBankName("");
    setAccountNumber("");
    setAccountType("");
    setIfsc("");
    setBranchAddress("");
  };
  const handleShow = (data) => setShow(data);

  function handleCloseSuccess() {
    handleClose();
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  //   search select

  const [optionsFirm, setOptionsFirm] = useState([]);
  const [optionsOperator, setOptionsOperator] = useState([]);
  const [selectedOptionFirm, setSelectedOptionFirm] = useState(null);

  const [selectedOptionBill, setSelectedOptionBill] = useState(null);
  const [isGettingDetails, setIsGettingDetails] = useState(false);
  const [details, setDetails] = useState([]);
  // const handleChangeBill = async (selectedOptionBill) => {
  //   setSelectedOptionBill(selectedOptionBill);
  //   if (selectedOptionBill?.value) {
  //     let operators = await getOprators(selectedOptionBill?.value);
  //     setOptionsOperator(operators);
  //   }
  // };
  //   operator select

  const [selectedOptionOperator, setSelectedOptionOperator] = useState(null);

  const handleChangeOperator = (selectedOptionOperator) => {
    setSelectedOptionOperator(selectedOptionOperator);
    console.log("Selected Option:", selectedOptionOperator);
  };

  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  async function getRows(selectedSite, searchInput, selectedService) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
        params: {
          // site_code: selectedSite?.value || "",
          search_input: searchInput,
          // service: selectedService?.value || "",
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
          ledger_master: "Vendor",
        },
      });

      setRows(response?.data?.data);

      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function printOrExportData(
    isPrint,
    selectedSite,
    searchInput,
    selectedService
  ) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getutilitybill`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          site_code: selectedSite?.value || "",
          search_input: searchInput,
          service: selectedService?.value || "",
          paginate: false,
        },
      });

      const tableData = response?.data?.data;

      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                font-family: Arial, sans-serif;
              }
              .table-wrapper {
                width: 100%;
                border-collapse: collapse;
              }
              .table-wrapper th, .table-wrapper td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
              }
              .table-wrapper th {
                background-color: #f2f2f2;
              }
              @media print {
                @page {
                  size: A4 landscape;
                  margin: 15mm;
                }
              }
            </style>
          </head>
          <body>
            <h1>All Bills</h1>
            <table class="table-wrapper">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Firm/Person</th>
                  <th>Code</th>
                  <th>Operator</th>
                  <th>Consumer code</th>
                  <th>New Bill Amount</th>
                  <th>Due Date</th>
                  <th>Payment Status</th>
                  <th>Active/Inactive</th>
                </tr>
              </thead>
              <tbody>
                               ${tableData
                                 .map(
                                   (row, index) => `
                      <tr>
                        <td>${index + 1}</td>
                        <td>${row?.sitedata?.name || ""}</td>
                        <td>${row?.site_code || ""}</td>
                        <td>${row?.operator?.operator || ""}</td>
                        <td>${row.account || ""}</td>
                        <td>${row.last_bill_amount || ""}</td>
                        <td>${
                          row?.last_bill_due_date
                            ? moment(row?.last_bill_due_date).format(
                                "DD MMM YYYY"
                              )
                            : ""
                        }</td>
                        <td>${
                          row?.payment_status == 0
                            ? "Unpaid"
                            : row?.payment_status == 1
                            ? "Partial"
                            : row?.payment_status == 2
                            ? "Paid"
                            : row?.payment_status == 3
                            ? "Failed"
                            : ""
                        }</td>
                        <td>${row?.is_active ? "Active" : "Inactive"}</td>
                      </tr>
                    `
                                 )
                                 .join("")}
              </tbody>
            </table>
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = window.close;
              };
            </script>
          </body>
        </html>
      `;

        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": index + 1,
            "Firm/Person": item?.sitedata?.name || "",
            Code: item?.site_code || "",
            Operator: item?.operator?.operator || "",
            "Consumer Code": item.account || "",
            "New Bill Amount": item.last_bill_amount || "",
            "Due Date": item?.last_bill_due_date
              ? moment(item?.last_bill_due_date).format("DD MMM YYYY")
              : "",
            "Payment Status":
              item?.payment_status == 0
                ? "Unpaid"
                : item?.payment_status == 1
                ? "Partial"
                : item?.payment_status == 2
                ? "Paid"
                : item?.payment_status == 3
                ? "Failed"
                : "",
            "Active/Inactive": item?.is_active ? "Active" : "Inactive",
          };

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `all_bills.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function handleAddBank(event) {
    event && event?.preventDefault();

    setIsBillAdding(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        id: "",
        contractor_id: show?.id,
        bank_name: bankName,
        account_no: accountNumber,
        ifsc_code: ifsc,
        branch: branchAddress,
        account_type: accountType,
        bank_code: bankCode,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updatevendorbank`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 3000);
        // setPage("0");
        handleClose();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({
        title: "Error",
        err: error?.response?.data?.msg || error?.message,
      });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsBillAdding(false);
    }
  }

  async function getDetails() {
    setIsGettingDetails(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendor/${displayModal?.id}`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setDetails(response.data?.data?.contractor_bank || []);
      } else {
        setDetails([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingDetails(false);
    }
  }

  useEffect(() => {
    getRows(selectedSite, searchInput, selectedService);
  }, [page, limit]);

  useEffect(() => {
    if (!searchInput) {
      getRows(selectedSite, "", selectedService);
    }
  }, [searchInput]);

  useEffect(() => {
    if (displayModal?.code) {
      getDetails();
    }
  }, [displayModal]);

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Vendor Profile</h1>
        </div>
        <div className="d-flex mb-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (page == 0) {
                getRows(selectedSite, searchInput, selectedService);
              } else {
                setPage(0);
              }
            }}
            className="maxw-300 d-flex "
          >
            <input
              type="search"
              className="input-box "
              placeholder="Search"
              value={searchInput}
              required
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              disabled={!searchInput.length}
              className="button button-brown ms-2"
              type="submit"
            >
              <span>
                <svg className="icon">
                  <use href="#icon_search"></use>
                </svg>
              </span>
            </button>
          </form>
          <div className="ms-auto">
            {/* <button
              className="button button-brown px-4"
              type="button"
              onClick={() => setDisplayModal(true)}
            >
              Download sample
            </button>
            <button className="button button-brown px-4 ms-5" type="button">
              Bulk upload
            </button> */}
            {/* <button
              className="button button-brown px-4 ms-5"
              type="button"
              onClick={handleShow}
            >
              + Add Bills
            </button> */}
            {/* <button
              type="button"
              className="button button-brown mr-30 ml-30"
              onClick={() =>
                printOrExportData(
                  true,
                  selectedSite,
                  searchInput,
                  selectedService
                )
              }
              disabled={!rows.length || isPrinting}
            >
              {isPrinting ? <Loader /> : "Print"}
            </button> */}
          </div>
        </div>
        {/* <div className="d-flex">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose User
            </label>

            <Select
              value={selectedSite}
              onChange={(option) => {
                setSelectedSite(option);
                if (page == 0) {
                  getRows(option, searchInput, selectedService);
                } else {
                  setPage(0);
                }
              }}
              options={optionsFirm.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Service
            </label>

            <Select
              value={selectedService}
              onChange={(option) => {
                setSelectedService(option);
                if (page == 0) {
                  getRows(selectedSite, searchInput, option);
                } else {
                  setPage(0);
                }
              }}
              options={serviceList.map((service) => ({
                value: service?.service,
                label: service?.service,
              }))}
              isSearchable
              isClearable
            />
          </div>
        </div> */}

        <div ref={componentRef} className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd">Sr. No.</th>
                <th>Name</th>
                <th>Code</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{parseInt(page) * limit + index + 1}</td>
                    {/* <td>{index + 1}</td> */}
                    <td>{row?.name}</td>
                    <td>{row?.code}</td>
                    <td>
                      <button
                        className="button"
                        type="button"
                        onClick={() => {
                          // setDisplayModal(row);
                          handleShow(row);
                        }}
                      >
                        <svg className="icon fs-22">
                          <use href="#icon_add"></use>
                        </svg>
                      </button>
                      <button
                        className="button"
                        type="button"
                        onClick={() => {
                          setDisplayModal(row);
                        }}
                      >
                        <svg className="icon fs-22">
                          <use href="#icon_eye"></use>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={2}>2</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>
      {/* modal himcon */}
      <Modal
        show={displayModal}
        centered
        onHide={() => {
          setDisplayModal(false);
          setDetails([]);
        }}
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-min-w90"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="text-center w-100 text-brown"
          >
            {displayModal?.name} - {displayModal?.code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-gray pt-0">
          <div className="tbl-scroller">
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th>Bank code</th>
                  <th>Bank name</th>
                  <th>Account number</th>
                  <th>Account type</th>
                  <th>IFSC</th>
                  <th>Branch address</th>
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>
              {!isGettingDetails && (
                <tbody>
                  {details.map((row, index) => (
                    <tr>
                      <td>{row?.bank_code}</td>
                      <td>{row?.bank_name}</td>
                      <td>{row?.account_no}</td>
                      <td>{row?.account_type}</td>
                      <td>{row?.ifsc_code}</td>
                      <td>{row?.branch}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {isGettingDetails && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader color={"#aa741f"} />
              </div>
            )}
            {!details.length && !isGettingDetails && (
              <div className="d-flex jc-center">
                <span>No data found</span>
              </div>
            )}
            {/* <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* aside pop */}

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-brown">
            <span>Add New Bank For</span>
            <br />
            <span>
              {show?.code} - {show?.name}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-gray">
          <form onSubmit={handleAddBank}>
            <div className="mb-2">
              <label htmlFor="Operator" className="mb-10 d-block">
                Bank Code
              </label>
              <input
                value={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                type="text"
                className="input-box"
                placeholder="Enter bank code"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="Operator" className="mb-10 d-block">
                Bank Name
              </label>
              <input
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                type="text"
                className="input-box"
                placeholder="Enter bank code"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="Operator" className="mb-10 d-block">
                Account Number
              </label>
              <input
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                type="text"
                className="input-box"
                placeholder="Enter account number"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="Operator" className="mb-10 d-block">
                Account Type
              </label>
              <select
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
                className="input-box"
                required
              >
                <option value="">Select account type</option>
                <option value="Savings">Savings</option>
                <option value="Current">Current</option>
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="Operator" className="mb-10 d-block">
                IFSC
              </label>
              <input
                value={ifsc}
                onChange={(e) => setIfsc(e.target.value)}
                type="text"
                className="input-box"
                placeholder="Enter IFSC"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="Operator" className="mb-10 d-block">
                Branch Address
              </label>
              <textarea
                value={branchAddress}
                onChange={(e) => setBranchAddress(e.target.value)}
                className="input-box"
                placeholder="Enter branch address"
                required
              ></textarea>
            </div>

            <div className="mb-4 d-flex justify-content-end">
              <button
                onClick={handleClose}
                className="button button-default px-4 me-5"
                type="button"
              >
                Cancel
              </button>
              <button
                disabled={isBillAdding}
                className="button button-brown px-4"
                type="submit"
              >
                {isBillAdding ? <Loader /> : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default ProfileVendor;
