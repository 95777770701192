import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import LayoutOutlet from "./LayoutOutlet";
import AllCodeSheet from "../pages/AllCodeSheet";
import DynamicReport from "../pages/DynamicReport";
import DailyVendorRequirement from "../pages/reports/DailyVendorRequirement";
import HardCopyOfBillReceivings from "../pages/reports/HardCopyOfBillReceivings";
import ItemMaster from "../pages/ItemMaster";
import VendorPortalBillUpdation from "../pages/reports/VendorPortalBillUpdation";
import DailyCashTransactions from "../pages/DailyCashTransactions";
import VehicleChallan from "../pages/reports/VehicleChallan";
import TotalStaffDetails from "../pages/reports/TotalStaffDetails";
import VehicleService from "../pages/reports/VehiceService";
import Donations from "../pages/reports/Donations";
import DailyTransactionHeadOffice from "../pages/DailyTransactionHeadOffice";
import HeadOfficeCashReceiving from "../pages/HeadOfficeCashReceiving";
import HeadOfficeCashPayment from "../pages/HeadOfficeCashPayment";
import AllBill from "../pages/AllBill";
import CashTrackOffice from "../pages/CashTrackOffice";
import SiteConsumption from "../pages/SiteConsumption";
import HomeExpenses from "../pages/reports/HomeExpenses";
import PurchaseVerify from "../pages/reports/PurchaseVerify";
import PurchaseDiscrepancy from "../pages/PurchaseDiscrepancy";
import PurchaseConslusion from "../pages/PurchaseConclusion";
import VendorCode from "../pages/VendorCode";
import HeadOfficeCashApproval from "../pages/HeadOfficeCashApproval";
import PaidBill from "../pages/PaidBill";
import ThankYou from "../pages/ThankYou";
import VendorQuotes from "../pages/VendorQuotes";
import BalanceToPay from "../pages/BalanceToPay";
import InStock from "../pages/InStock";
import ToBeRefunded from "../pages/ToBeRefunded";
import Consolidated from "../pages/Consolidated";
import VendorQuotesApproved from "../pages/VendorQuotesApproved";
import ProfileVendor from "../pages/ProfileVendor";
import ProfileContractorSupplier from "../pages/ProfileContractorSupplier";
import SiteDiscrepancy from "../pages/SiteDiscrepancy";
import SiteConclusion from "../pages/SiteConclusion";

function Routing() {
  return (
    <>
      <BrowserRouter
        basename={process.env.REACT_APP_ENV === "dev" ? "/testhim" : "/"}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/vendor/:code/:emp" element={<VendorCode />} />
          <Route path="/vendor-success" element={<ThankYou />} />
          <Route path="/" element={<LayoutOutlet />}>
            <Route index element={<AllCodeSheet />}></Route>
            <Route
              path="daily_transaction_head_office"
              element={<DailyTransactionHeadOffice />}
            ></Route>
            <Route
              path="head_office_cash_receiving"
              element={<HeadOfficeCashReceiving />}
            ></Route>
            <Route
              path="head_office_cash_payment"
              element={<HeadOfficeCashPayment />}
            ></Route>
            <Route
              path="head_office_cash_approval"
              element={<HeadOfficeCashApproval />}
            ></Route>
            <Route
              path="cash_track/cash_track_office"
              element={<CashTrackOffice />}
            ></Route>
            <Route
              path="cash_track/daily_cash_transactions"
              element={<DailyCashTransactions />}
            ></Route>
            <Route path="profile/vendor" element={<ProfileVendor />}></Route>
            <Route
              path="profile/contractor-supplier"
              element={<ProfileContractorSupplier />}
            ></Route>
            <Route
              path="home_expenses/site_consumption"
              element={<SiteConsumption />}
            ></Route>
            <Route
              path="home_expenses/item_master"
              element={<ItemMaster />}
            ></Route>

            <Route path="bills/all_bill" element={<AllBill />}></Route>
            <Route path="bills/paid_bill" element={<PaidBill />}></Route>
            {/* <Route path="report/:report" element={<DynamicReport />}></Route> */}
            <Route
              path="report/daily_vendor_requirement"
              element={<DailyVendorRequirement />}
            ></Route>
            <Route
              path="report/hard_copy_of_bill_receivings"
              element={<HardCopyOfBillReceivings />}
            ></Route>
            <Route
              path="report/vendor_portal_bill_updation"
              element={<VendorPortalBillUpdation />}
            ></Route>
            <Route
              path="report/daily_cash_transactions"
              element={<DailyCashTransactions />}
            ></Route>
            <Route
              path="report/vehicle_challan"
              element={<VehicleChallan />}
            ></Route>
            <Route
              path="report/total_staff_details"
              element={<TotalStaffDetails />}
            ></Route>
            <Route
              path="report/vehicle_service"
              element={<VehicleService />}
            ></Route>
            <Route path="report/donations" element={<Donations />}></Route>
            <Route
              path="report/home_expense"
              element={<HomeExpenses />}
            ></Route>
            <Route
              path="report/purchase_verify"
              element={<PurchaseVerify />}
            ></Route>
            <Route
              path="discrepancy/purchase"
              element={<PurchaseDiscrepancy />}
            ></Route>
            <Route
              path="conclusion/purchase"
              element={<PurchaseConslusion />}
            ></Route>
            <Route
              path="discrepancy/site"
              element={<SiteDiscrepancy />}
            ></Route>
            <Route path="conclusion/site" element={<SiteConclusion />}></Route>
            {/* <Route
              path="contractor_supplier_rate_approved"
              element={<VendorQuotes />}
            ></Route> */}
            <Route
              path="contractor_supplier_rate_approved/quote_to_approve"
              element={<VendorQuotes />}
            ></Route>
            <Route
              path="contractor_supplier_rate_approved/approved_quote"
              element={<VendorQuotesApproved />}
            ></Route>

            <Route
              path="engineering-sheet/consolidated"
              element={<Consolidated />}
            ></Route>
            <Route
              path="engineering-sheet/balance-to-pay"
              element={<BalanceToPay />}
            ></Route>
            <Route
              path="engineering-sheet/in-stock"
              element={<InStock />}
            ></Route>
            <Route
              path="engineering-sheet/to-be-refunded"
              element={<ToBeRefunded />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
